import { render, staticRenderFns } from "./Scrims2ListPage.vue?vue&type=template&id=69270334&scoped=true&"
import script from "./Scrims2ListPage.vue?vue&type=script&lang=js&"
export * from "./Scrims2ListPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69270334",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1259766950/src/web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('69270334')) {
      api.createRecord('69270334', component.options)
    } else {
      api.reload('69270334', component.options)
    }
    module.hot.accept("./Scrims2ListPage.vue?vue&type=template&id=69270334&scoped=true&", function () {
      api.rerender('69270334', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/scrims2/Scrims2ListPage.vue"
export default component.exports