import { render, staticRenderFns } from "./BookmarkToggle.vue?vue&type=template&id=b8b85edc&scoped=true&"
import script from "./BookmarkToggle.vue?vue&type=script&lang=js&"
export * from "./BookmarkToggle.vue?vue&type=script&lang=js&"
import style0 from "./BookmarkToggle.vue?vue&type=style&index=0&id=b8b85edc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8b85edc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1259766950/src/web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b8b85edc')) {
      api.createRecord('b8b85edc', component.options)
    } else {
      api.reload('b8b85edc', component.options)
    }
    module.hot.accept("./BookmarkToggle.vue?vue&type=template&id=b8b85edc&scoped=true&", function () {
      api.rerender('b8b85edc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/generic/BookmarkToggle.vue"
export default component.exports